/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Theme } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/styles';

import React from 'react';

const useStyles = makeStyles((theme:Theme) => ({
    imageIcon: {
      display: 'flex',
      height: 'inherit',
      width: 'inherit',
      marginLeft: '-1px'
    },
    iconRoot: {
        textAlign: 'center'
      },
    fill : {
        fill: theme.palette.navigation.color
    }
  }));

export const VeecodeLogoIcon = () => {

 const classes = useStyles()

 return(
    <Icon classes={{root: classes.iconRoot}}>
        <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="75.000000pt"
        height="60.000000pt"
        viewBox="0 0 128.000000 147.000000"
        enableBackground="new 0 0 145 137"
        className={classes.imageIcon}
        >
        <path
           fill="#ffffff00"
            opacity="1.000000"
            stroke="none"
            d="
                M146.000000,75.000000 
                C146.000000,95.965927 146.000000,116.931854 146.000000,137.948883 
                C97.779205,137.948883 49.558342,137.948883 1.000000,137.948883 
                C1.000000,117.647018 1.000000,97.292168 1.396000,76.475220 
                C3.862166,76.805733 6.743462,77.014465 7.825831,78.518112 
                C9.797928,81.257790 11.775883,84.740578 11.850750,87.957565 
                C12.069649,97.363449 11.183575,106.788803 11.053758,116.210976 
                C10.945433,124.073257 16.011984,130.130905 23.466560,133.121170 
                C31.541161,136.360138 39.770229,134.461060 48.314816,134.777039 
                C50.142372,131.784393 52.183235,128.706802 46.613823,127.160240 
                C44.782547,127.139114 43.370689,127.183678 41.644142,127.139336 
                C40.901932,127.110741 40.474403,127.171051 39.613132,127.168900 
                C37.139713,127.050461 35.100029,126.994484 32.989487,126.683777 
                C32.588661,126.596863 32.258698,126.764664 31.563129,126.971741 
                C24.157024,124.993340 21.444740,119.404053 21.206530,112.998428 
                C20.996887,107.360985 21.920956,101.561707 23.162474,96.017082 
                C25.196684,86.932304 22.031250,80.049995 15.036127,74.759956 
                C12.292994,72.685463 12.565009,72.093376 15.058997,70.099426 
                C19.319721,66.692970 23.128569,62.589062 22.942476,56.577797 
                C22.722965,49.487083 22.943747,42.236755 21.499638,35.365414 
                C19.516422,25.928898 24.801544,18.003798 34.430431,17.905760 
                C37.259579,17.876955 40.089222,17.899176 42.918629,17.893192 
                C49.782558,17.878677 50.345516,17.241245 49.445148,9.967205 
                C43.880547,9.967205 38.210674,10.392815 32.626122,9.883297 
                C18.731972,8.615639 10.534466,19.377710 10.988717,29.060867 
                C11.422098,38.299129 11.923322,47.543472 11.974350,56.787464 
                C12.014726,64.101738 7.878571,68.015060 1.000000,68.000000 
                C1.000000,45.701607 1.000000,23.403212 1.000000,1.052409 
                C49.222729,1.052409 97.445534,1.052409 146.000000,1.052409 
                C146.000000,23.353134 146.000000,45.707909 145.585510,68.520958 
                C137.702393,68.783394 130.545441,60.931992 131.188202,53.504192 
                C131.780350,46.661610 132.518265,39.831646 133.514252,32.800507 
                C133.613617,30.725225 133.390686,28.845558 133.101303,26.557638 
                C133.625381,18.596283 127.049179,12.455959 120.633194,11.320654 
                C112.347130,9.854440 103.772072,10.021446 94.601730,9.439480 
                C95.317955,12.629036 95.793793,14.748091 96.302170,17.012043 
                C101.114777,17.012043 105.677864,16.490038 110.073616,17.125490 
                C118.132851,18.290533 122.572449,21.105434 122.862694,28.035694 
                C123.137009,34.585587 122.577744,41.253006 121.600922,47.750847 
                C120.489731,55.142525 123.573654,66.234070 129.812027,69.906593 
                C130.713120,70.437057 131.456894,71.234741 132.450134,72.054596 
                C131.540283,72.923645 131.031998,73.594711 130.366013,74.017052 
                C122.210144,79.189133 120.942818,87.358047 121.210945,95.920448 
                C121.389664,101.627350 122.742050,107.295959 122.945740,113.003960 
                C123.261749,121.859619 118.166924,126.826584 109.215149,126.973343 
                C104.927788,127.043633 100.638321,126.985451 96.261276,126.985451 
                C96.261276,129.877426 96.261276,132.272415 96.261276,134.958542 
                C102.838921,134.958542 109.206009,135.523575 115.428223,134.815521 
                C123.209618,133.930069 130.835144,130.175385 132.400665,121.958717 
                C135.507919,112.361076 133.836609,103.113182 131.928513,93.459160 
                C132.533691,80.578537 134.302872,78.299088 146.000000,75.000000 
                M95.353096,49.980083 
                C96.873871,46.578102 98.394653,43.176121 100.063957,39.441891 
                C98.614990,38.435234 97.273254,37.181385 95.689415,36.456676 
                C87.468735,32.695187 78.638962,32.022167 69.958847,33.269192 
                C57.863949,35.006813 48.134392,40.810955 43.642303,52.994900 
                C39.775414,63.483105 40.186153,74.333771 41.702019,85.151649 
                C42.702072,92.288467 45.841362,98.814720 51.250847,103.392792 
                C64.029121,114.207138 78.840134,114.179016 93.902435,109.400139 
                C101.634422,106.946968 101.486786,106.515114 98.375671,98.972130 
                C98.068718,98.227928 98.064468,97.358040 97.752441,96.616913 
                C96.637306,93.968163 95.238510,91.855133 91.943062,94.040115 
                C86.988632,97.325043 81.266731,97.311722 75.804237,96.877899 
                C72.322792,96.601410 68.108673,95.347237 65.752159,92.997757 
                C58.296120,85.563965 56.459141,75.973511 58.677761,65.942268 
                C60.177330,59.162132 62.180813,52.354816 69.914474,49.343632 
                C75.828049,47.041126 81.518188,47.555496 87.377968,49.075474 
                C89.821228,49.709225 92.333641,50.076401 95.353096,49.980083 
                z"
        />
        <path
          //  fill="#0CF4C8"
            className={classes.fill}
            opacity="1.000000"
            stroke="none"
            d="
                M47.930313,134.824844 
                C39.770229,134.461060 31.541161,136.360138 23.466560,133.121170 
                C16.011984,130.130905 10.945433,124.073257 11.053758,116.210976 
                C11.183575,106.788803 12.069649,97.363449 11.850750,87.957565 
                C11.775883,84.740578 9.797928,81.257790 7.825831,78.518112 
                C6.743462,77.014465 3.862166,76.805733 1.396000,76.006561 
                C1.000000,73.629440 1.000000,71.258888 1.000000,68.444168 
                C7.878571,68.015060 12.014726,64.101738 11.974350,56.787464 
                C11.923322,47.543472 11.422098,38.299129 10.988717,29.060867 
                C10.534466,19.377710 18.731972,8.615639 32.626122,9.883297 
                C38.210674,10.392815 43.880547,9.967205 49.445148,9.967205 
                C50.345516,17.241245 49.782558,17.878677 42.918629,17.893192 
                C40.089222,17.899176 37.259579,17.876955 34.430431,17.905760 
                C24.801544,18.003798 19.516422,25.928898 21.499638,35.365414 
                C22.943747,42.236755 22.722965,49.487083 22.942476,56.577797 
                C23.128569,62.589062 19.319721,66.692970 15.058997,70.099426 
                C12.565009,72.093376 12.292994,72.685463 15.036127,74.759956 
                C22.031250,80.049995 25.196684,86.932304 23.162474,96.017082 
                C21.920956,101.561707 20.996887,107.360985 21.206530,112.998428 
                C21.444740,119.404053 24.157024,124.993340 31.845621,126.985306 
                C32.682598,126.952576 32.871475,126.945541 33.060349,126.938507 
                C35.100029,126.994484 37.139713,127.050461 39.777641,127.315674 
                C40.903538,127.426018 41.431183,127.327133 41.958828,127.228249 
                C43.370689,127.183678 44.782547,127.139114 46.776848,127.499619 
                C47.549633,130.211395 47.739975,132.518127 47.930313,134.824844 
                z"
        />
        <path
         //   fill="#09F7CA"
            className={classes.fill}
            opacity="1.000000"
            stroke="none"
            d="
                M146.000000,74.571533 
                C134.302872,78.299088 132.533691,80.578537 131.688812,93.794617 
                C131.601929,103.752693 131.822052,112.959389 132.042191,122.166092 
                C130.835144,130.175385 123.209618,133.930069 115.428223,134.815521 
                C109.206009,135.523575 102.838921,134.958542 96.261276,134.958542 
                C96.261276,132.272415 96.261276,129.877426 96.261276,126.985451 
                C100.638321,126.985451 104.927788,127.043633 109.215149,126.973343 
                C118.166924,126.826584 123.261749,121.859619 122.945740,113.003960 
                C122.742050,107.295959 121.389664,101.627350 121.210945,95.920448 
                C120.942818,87.358047 122.210144,79.189133 130.366013,74.017052 
                C131.031998,73.594711 131.540283,72.923645 132.450134,72.054596 
                C131.456894,71.234741 130.713120,70.437057 129.812027,69.906593 
                C123.573654,66.234070 120.489731,55.142525 121.600922,47.750847 
                C122.577744,41.253006 123.137009,34.585587 122.862694,28.035694 
                C122.572449,21.105434 118.132851,18.290533 110.073616,17.125490 
                C105.677864,16.490038 101.114777,17.012043 96.302170,17.012043 
                C95.793793,14.748091 95.317955,12.629036 94.601730,9.439480 
                C103.772072,10.021446 112.347130,9.854440 120.633194,11.320654 
                C127.049179,12.455959 133.625381,18.596283 133.046814,26.985897 
                C133.103180,29.546980 133.147568,31.271551 133.191971,32.996124 
                C132.518265,39.831646 131.780350,46.661610 131.188202,53.504192 
                C130.545441,60.931992 137.702393,68.783394 145.585510,68.989616 
                C146.000000,70.714355 146.000000,72.428719 146.000000,74.571533 
                z"
        />
        <path
         //   fill="#06F9CC"
            className={classes.fill}
            opacity="1.000000"
            stroke="none"
            d="
                M95.083847,50.272438 
                C92.333641,50.076401 89.821228,49.709225 87.377968,49.075474 
                C81.518188,47.555496 75.828049,47.041126 69.914474,49.343632 
                C62.180813,52.354816 60.177330,59.162132 58.677761,65.942268 
                C56.459141,75.973511 58.296120,85.563965 65.752159,92.997757 
                C68.108673,95.347237 72.322792,96.601410 75.804237,96.877899 
                C81.266731,97.311722 86.988632,97.325043 91.943062,94.040115 
                C95.238510,91.855133 96.637306,93.968163 97.752441,96.616913 
                C98.064468,97.358040 98.068718,98.227928 98.375671,98.972130 
                C101.486786,106.515114 101.634422,106.946968 93.902435,109.400139 
                C78.840134,114.179016 64.029121,114.207138 51.250847,103.392792 
                C45.841362,98.814720 42.702072,92.288467 41.702019,85.151649 
                C40.186153,74.333771 39.775414,63.483105 43.642303,52.994900 
                C48.134392,40.810955 57.863949,35.006813 69.958847,33.269192 
                C78.638962,32.022167 87.468735,32.695187 95.689415,36.456676 
                C97.273254,37.181385 98.614990,38.435234 100.063957,39.441891 
                C98.394653,43.176121 96.873871,46.578102 95.083847,50.272438 
                z"
        />
        <path
         //   fill="#27D8B2"
            className={classes.fill}
            opacity="1.000000"
            stroke="none"
            d="
                M132.400665,121.958717 
                C131.822052,112.959389 131.601929,103.752693 131.621490,94.210541 
                C133.836609,103.113182 135.507919,112.361076 132.400665,121.958717 
                z"
        />
        <path
          //  fill="#1CD0AE"
            className={classes.fill}
            opacity="1.000000"
            stroke="none"
            d="
                M48.314812,134.777039 
                C47.739975,132.518127 47.549633,130.211395 47.196262,127.565308 
                C52.183235,128.706802 50.142372,131.784393 48.314812,134.777039 
                z"
        />
        <path
          //  fill="#8FD3C6"
            className={classes.fill}
            opacity="1.000000"
            stroke="none"
            d="
            M133.514252,32.800507 
            C133.147568,31.271551 133.103180,29.546980 133.113281,27.394150 
            C133.390686,28.845558 133.613617,30.725225 133.514252,32.800507 
            z"
        />
        <path
         //   fill="#1CD0AE"
            className={classes.fill}
            opacity="1.000000"
            stroke="none"
            d="
            M41.644142,127.139343 
            C41.431183,127.327133 40.903538,127.426018 40.211380,127.378128 
            C40.474403,127.171051 40.901932,127.110741 41.644142,127.139343 
            z"
        />
        <path
         //   fill="#1CD0AE"
            className={classes.fill}
            opacity="1.000000"
            stroke="none"
            d="
            M32.989487,126.683784 
            C32.871475,126.945541 32.682598,126.952576 32.211224,126.946030 
            C32.258698,126.764664 32.588661,126.596863 32.989487,126.683784 
            z"
        />
        </svg>
    </Icon>
 )
}